import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import UserLeft from '../PersonUserLeft.vue'
export default{
	name:'UserPurchaseList',
	components:{
		'Header': Header,
		'Footer':Footer,
		'UserLeft':UserLeft
	}
}